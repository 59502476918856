<template>
  <div class="speedPage">
    <!-- <headerComponent /> -->
    <img
      @click="handleJSDownLoad"
      :src="require('@/assets/bgc8.png')"
      alt=""
      class="banner_img"
    />
    <div class="content_box">
      <div class="left">
        <div class="info_box">
          <div class="info_item" v-for="(item, index) in infoList" :key="index">
            <div class="left_box">
              <img class="img" :src="item.titSrc" alt="" />

              <div class="title">{{ item.title }}</div>

              <div class="text">
                {{ item.text }}
              </div>
            </div>
            <div class="right_box">
              <!-- <videoPlayerPage
                  v-if="item.videoSrc"
                  :imgSrc="item.imgSrc"
                  :videoSrc="item.videoSrc"
                /> -->

              <img class="img" :src="item.imgSrc" alt="" />

              <!-- <img
                class="Start"
                v-if="item.videoSrc"
                :src="require('assets/Start.png')"
                alt=""
              /> -->
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="commonFooter_box">
      <commonFooter />
    </div>

    <el-dialog :visible.sync="dialogVisible" :before-close="beforeClose">
      <videoPlayerPage v-if="videoSrc" :imgSrc="imgSrc" :videoSrc="videoSrc" />
    </el-dialog>
  </div>
</template>

<script>
//例如：import  from '';
import commonFooter from "components/footer/commonFooter.vue";
import videoPlayerPage from "components/videoInfoComponents/videoPlayerPage.vue";
import headerComponent from "./headerComponent";
export default {
  name: "speedPage",

  //import引入的组件需要注入到对象中才能使用
  components: {
    // headerComponent,
    videoPlayerPage,
    commonFooter,
  },
  data() {
    //这里存放数据
    return {
      imgSrc: "",
      videoSrc: "",
      dialogVisible: false,
      infoList: [
        {
          imgSrc: require("assets/images/hamePage/videobgc1.png"),
          titSrc: require("assets/images/hamePage/titimg1.png"),
          title: "智能推文",
          text: "根据鼠标选中或者输入的文本，精准匹配30余种中文语境算法引擎，实时智能提供优选内容方案，一键复制进编辑框，大幅降低创作难度、激发创作灵感。（隐藏功能：使用成语Office/WPS版，采纳推荐内容时可以自动插入规范的脚注尾注，大学论文写作神器）",
          videoSrc: "https://www.writemall.com/download/成语极速版智能推文.mp4",
        },
        {
          imgSrc: require("assets/images/hamePage/videobgc7.png"),
          titSrc: require("assets/images/hamePage/titimg7.png"),
          title: "AI成文",
          text: "根据鼠标选中或者输入的文本，精准匹配30余种中文语境算法引擎，实时智能提供优选内容方案，一键复制进编辑框，大幅降低创作难度、激发创作灵感。（隐藏功能：使用成语Office/WPS版，采纳推荐内容时可以自动插入规范的脚注尾注，大学论文写作神器）",
        },
        {
          imgSrc: require("assets/images/hamePage/videobgc2.png"),
          titSrc: require("assets/images/hamePage/titimg2.png"),
          title: "智能推图",
          text: "制作PPT材料时，“智能推图”可以根据选中的内容推荐站内站外的图片；（隐藏功能：聊天斗图，站内站外粘贴复制立即可用）",
          videoSrc:
            "https://www.writemall.com/download/成语极速版·智能推图.mp4",
        },
        {
          imgSrc: require("assets/images/hamePage/videobgc3.png"),
          titSrc: require("assets/images/hamePage/titimg3.png"),
          title: "AI绘画",
          text: "当灵感匮乏时，可以输入指令，驱动AI智能创造独一无二的原创画作；（隐藏功能：独特、有趣、解压的绘画创作）",
          videoSrc: "",
        },
        {
          imgSrc: require("assets/images/hamePage/videobgc4.png"),
          titSrc: require("assets/images/hamePage/titimg4.png"),
          title: "网页采集",
          text: "找资料、浏览网页时，使用“网页采集”功能，可以将当前网页转化为word文档后存入自带的网盘库，并可以作为智能推文的语境库（隐藏功能：一键在手，天下文章我有）",
          videoSrc: "",
        },
        {
          imgSrc: require("assets/images/hamePage/videobgc5.png"),
          titSrc: require("assets/images/hamePage/titimg5.png"),
          title: "文库",
          text: "全面汇聚领导人时事新闻、重大会议、新闻联播、新闻发布、党内法规、法律法规、工作报告、战略规划、政策文件、裁判文书、执法文书等十余类专业文献，这些文档都是word版本，可以随时随地查看、下载、分享。更有公文写作、基层党建、个人文稿、法律文书、合同协议、机关制度、活动策划、节日庆典、大学生类、教师专用、文秘专用、公司管理、企业制度、商业计划书等优秀模板。以及公文写作、儒家经典、中小学作文等闪亮金句。（隐藏功能：有个“汇编成册”的独特功能，将想要的文档添加汇编，点击汇编成册则可以将多篇文档汇编成为自带目录的一个文档）",
          videoSrc: "",
        },
        {
          imgSrc: require("assets/images/hamePage/videobgc6.png"),
          titSrc: require("assets/images/hamePage/titimg6.png"),
          title: "截图取字",
          text: "当想要将网页上不可复制的内容粘贴到文档中，或者碰到不可编辑的宣传册等PDF材料时，“截图取字”可以将截图区域中的文字识别转化为可编辑的文本，抛弃传统做法，无需下载转文本，毫秒级无死角、超高精确度，想取哪里截哪里",
          videoSrc: "",
        },
      ],
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    handleVideo(img, video) {
      if (video != "") {
        this.imgSrc = img;
        this.videoSrc = video;
        this.dialogVisible = true;
      }
    },

    handleJSDownLoad() {
      const link = document.createElement("a");
      link.href = "https://qiniu.writemall.com/ChengyuSpeedEdition/%E6%88%90%E8%AF%AD%E6%9E%81%E9%80%9F%E7%89%88%28windows%E7%B3%BB%E7%BB%9F%29.zip";
      link.download = "download";
      link.click();
    },

    beforeClose() {
      this.imgSrc = "";
      this.videoSrc = "";
      this.dialogVisible = false;
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
};
</script>
<style lang="scss" scoped>
.speedPage {
  position: absolute;
  background: #f5f5f2;
  top: 0;
  left: 0;
  width: 100%;

  .banner_img {
    width: 100%;
    cursor: pointer;
  }

  .commonFooter_box {
    margin-top: 20px;
    width: 100%;
  }
  .content_box {
    display: flex;
    justify-content: center;
    transform: translateY(-40px);

    .left {
      margin-right: 10px;
      .top {
        display: flex;
        position: relative;

        .sidebar {
          width: 190px;
          height: 470px;
          overflow-x: hidden;
          overflow-y: auto;
          margin-right: 10px;
          background-color: #fff;

          box-sizing: border-box;
          padding-top: 30px;

          .title {
            position: absolute;
            top: 0px;

            width: 190px;
            box-sizing: border-box;
            // margin-bottom: 23px;
            padding: 10px 20px;
            border-left: 3px solid #ff6900;
            font-size: 14px;
            font-weight: bold;
            color: #000;
            z-index: 100;
            background-color: #fff;
          }

          &::-webkit-scrollbar {
            width: 3px;
          }

          ::v-deep .el-menu {
            border: 0px;
            .el-submenu__title {
              overflow: hidden;
              height: 30px;
              line-height: 30px;
            }
            .el-menu-item {
              height: 30px;
              line-height: 30px;
              overflow: hidden;
              box-sizing: border-box;
              min-width: 190px;
              width: 190px;
            }
          }
        }

        .banner {
          width: 790px;
          height: 470px;
          margin-right: 10px;
          background-color: #fff;

          .img {
            width: 790px;
            height: 470px;
          }
        }

        .video {
          width: 190px;
          height: 470px;
          overflow: auto;
          background-color: #fff;

          .text {
            margin-top: 20px;
            padding-left: 12px;
            border-left: 3px solid #ff6500;
            font-size: 16px;
            font-weight: bold;
            margin-bottom: 12px;
          }

          .img_box {
            margin-left: 3px;
            width: 168px;
            margin-bottom: 20px;
            cursor: pointer;
            img {
              width: 100%;
            }
          }

          .video_item {
            padding: 20px;
            cursor: pointer;

            &:hover {
              background-color: #fffaf3;
            }
            img {
              width: 149px;
              height: 88px;
            }

            .video_title {
              margin: 17px 0 10px 0;

              font-size: 16px;
            }

            .video_related {
              margin-top: 10px;
              font-size: 12px;
              display: flex;
              justify-content: space-between;
              color: #666666;
              .heat {
                display: flex;
                align-items: center;
                .heat_icon {
                  width: 14px;
                  height: 14px;
                  margin-right: 10px;
                  background: url("~assets/images/info/hot.png") no-repeat;
                  background-size: 100% 100%;
                }
              }

              .score {
                display: flex;
                align-items: center;
                .score_icon {
                  width: 14px;
                  height: 14px;
                  margin-right: 10px;
                  background: url("~assets/images/writing/score_yellow.png")
                    no-repeat;
                  background-size: 100% 100%;
                }
              }

              .review {
                display: flex;
                align-items: center;
                .score_icon {
                  width: 14px;
                  height: 14px;
                  margin-right: 10px;
                  background: url("~assets/images/writing/review.png") no-repeat;
                  background-size: 100% 100%;
                }
              }
            }
          }
        }
      }

      .botton {
        margin-top: 10px;
        position: relative;

        img {
          width: 100%;
          height: 320px;
        }

        .btn {
          display: flex;
          position: absolute;
          bottom: 25px;
          left: 50%;
          transform: translateX(-50%);
          cursor: pointer;
          padding: 10px 20px;
          background: #ff6900;
          border-radius: 5px;
          color: #fff;
          font-family: PingFang SC;
          font-size: 18px;
          justify-content: center;
          align-items: center;

          img {
            width: 26px;
            height: auto;
            margin-right: 10px;
          }
        }
      }

      .info_box {
        .info_item {
          width: 1526px;
          box-sizing: border-box;
          background-color: #fff;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 69px 241px 60px 182px;
          margin-bottom: 10px;

          &:nth-child(5) {
            // background-color: red;
            padding: 69px 98px 60px 182px;
            .right_box {
              width: 635px;
            }
          }
          &:nth-child(6) {
            // background-color: red;
            padding: 69px 98px 60px 182px;
            .right_box {
              width: 635px;
            }
          }

          .left_box {
            width: 516px;
            box-sizing: border-box;
            padding: 64px 34px 48px 36px;
            box-shadow: 0px 0px 29px 0px rgba(158, 165, 195, 0.18);
            position: relative;

            .img {
              position: absolute;
              top: -25px;
              left: 0;
              width: 372.2px;
              height: 46.6px;
            }

            .title {
              font-size: 26px;
              font-weight: bold;
              margin-bottom: 30px;
            }

            .text {
              font-size: 16px;
              line-height: 30px;
            }
          }

          .right_box {
            width: 352px;
            position: relative;

            .Start {
              cursor: pointer;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              width: 61px;
              height: 61px;
            }

            .img {
              width: 100%;
            }
          }
        }
      }
    }

    .right {
      width: 350px;
      background-color: #fff;

      .Recommend_text {
        margin-top: 20px;
        font-size: 14px;
        font-weight: bold;
        padding: 5px 10px;
        border-left: 3px solid #ff6900;
      }

      .article_box {
        margin-top: 15px;
        padding: 0 0 0 16px;
        .item {
          cursor: pointer;
          font-size: 14px;
          margin-bottom: 23px;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          align-items: center;
          white-space: nowrap;

          &:hover {
            color: #ff6900;
          }
        }

        span {
          color: #4587ff;
        }

        .title {
          margin-bottom: 23px;
          font-size: 14px;
          font-weight: bold;
          color: #4587ff;
        }
      }
    }
  }
}
</style>
